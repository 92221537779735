import React from 'react'
import {Link} from 'react-router-dom'
import '../App.css'

const Nav = () => {
	return (
		<div className={"NavBar"}>
			{/*<div className={'MainNavSection'}>*/}
			{/*	<Link className={"NavLinks"} to={'/Home'}>*/}
			{/*		<Letters/>*/}
			{/*	</Link>*/}
			{/*</div>*/}
			<div className={'NavButton HomeNavSection'}>
				<Link className={"NavLinks"} to={'/Home'}>
					<div className={"NavText"}>
						HOME
					</div>
				</Link>
			</div>
			<div className={'NavButton AboutNavSection'}>
				<Link className={"NavLinks"} to={'/About'}>
					<div className={"NavText"}>
						ABOUT US
					</div>
				</Link>
			</div>
			<div className={'NavButton TeamNavSection'}>
				<Link className={"NavLinks"} to={'/Team'}>
					<div className={"NavText"}>
						TEAM
					</div>
				</Link>
			</div>
			<div className={'NavButton NewsNavSection'}>
				<Link className={"NavLinks"} to={'/News'}>
					<div className={"NavText"}>
						NEWS
					</div>
				</Link>
			</div>
			<div className={'NavButton ContactNavSection'}>
				<Link className={"NavLinks"} to={'/ContactUs'}>
					<div className={"NavText"}>
						CONTACT US
					</div>
				</Link>
			</div>
		</div>
	)
}

export default Nav