import React from 'react'
import '../App.css'

import CornellLogo from "../assets/cornell_logo.svg";
import MITLogo from "../assets/mit_logo.png";

const Footer = () => {
	return (
		<footer className={'Footer'}>
			{/*<a href={'https://cornell.edu/'} className={'CornellTechLogoLink'} target="_blank" rel="noopener noreferrer">*/}
			{/*	<img src={CornellLogo} alt='Cornell University' className={'CornellTechLogo'}/>*/}
			{/*</a>*/}
			{/*<a href={'https://mit.edu/'} target="_blank" className={'MITLogoLink'} rel="noopener noreferrer">*/}
			{/*	<img src={MITLogo} alt='MIT' className={'MITLogo'}/>*/}
			{/*</a>*/}
			<div style={{position: "absolute", top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)"}}>11 East Loop Road, NY, NY, 10044</div>
		</footer>
	)
}

export default Footer
